import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';

import Button from '../button/Button';
import Icon from '../icon/Icon';
import Typography from '../typography/Typography';

import { exportTextAsFile, getWallet } from '../../imports/utils/utils';

import { IconClose } from '../../assets/icons';

type OverlayDialogProps = {
  isOpen: boolean;
  handleClose: () => void;
};

const OverlayDialog = ({ isOpen, handleClose }: OverlayDialogProps) => {
  const { t } = useTranslation();

  const wallet = getWallet();

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <div className="fixed top-0 left-0 z-10 flex min-h-screen w-screen items-center justify-center text-white">
            <div
              role="presentation"
              className="absolute top-0 left-0 h-full w-full bg-black opacity-90"
              onClick={handleClose}
            />
            <div className="bg-dialog container-system absolute top-1/2 w-[calc(100%-(1rem*2))] -translate-y-1/2 rounded-3xl p-4 ">
              <header className="absolute top-0 left-0 flex w-full -translate-y-full items-center justify-between py-4">
                <Typography as="h2" size="xl">
                  {t('wallet')}
                </Typography>
                <button
                  type="button"
                  className="bg-dialog ml-auto flex h-7 w-7 shrink-0 cursor-pointer items-center justify-center rounded-full"
                  onClick={handleClose}
                >
                  <Icon icon={IconClose} color="white" />
                </button>
              </header>
              {wallet && (
                <>
                  <Typography as="h3" size="lg" className="text-center">
                    {t('show_mnemonic_phrase_title.this_is_your_mnemonic_phrase')}
                  </Typography>
                  <Typography as="h4" size="md" className="mt-4 text-center">
                    {t('show_mnemonic_phrase_title.be_careful')}
                  </Typography>
                  <div className="mt-10 grid grid-cols-3 gap-x-4 gap-y-3">
                    {wallet.mnemonic.phrase.split(' ').map((word, index) => (
                      <span
                        className="bg-word w-full rounded-xl py-1 text-center text-white/90"
                        key={word}
                      >
                        {`${index}. ${word}`}
                      </span>
                    ))}
                  </div>
                  <Button action={() => exportTextAsFile(wallet.mnemonic.phrase)} className="mt-8">
                    {t('export_secret_phrase')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default OverlayDialog;
