import { ethers } from 'ethers';
import Fuse from 'fuse.js';
import forge from 'node-forge';

import type { FilterCollectionProps, FilterInOrder } from '../types';

export const hashPassword = (password: string) => {
  const md = forge.md.sha1.create();
  md.update(password);
  const hex = md.digest().toHex();

  return hex;
};

export const getAuthErrorMessage = (errorCode: string) => {
  const errorMessagesSupported = [
    'auth/email-already-in-use',
    'auth/wrong-password',
    'auth/user-not-found',
  ];

  if (errorMessagesSupported.includes(errorCode)) {
    return errorCode;
  }

  return 'generic';
};

export const getWallet = () => {
  let wallet = localStorage.getItem('wallet');

  if (!wallet) {
    const newWallet = ethers.Wallet.createRandom();

    const { mnemonic } = newWallet;

    const mnemonicPhrase = mnemonic.phrase;

    localStorage.setItem('wallet', mnemonicPhrase);
    wallet = mnemonicPhrase;
  }

  if (wallet) {
    return ethers.Wallet.fromMnemonic(wallet);
  }

  return null;
};

/* export const client = (endpoint: string, { body, ...customConfig }: any = {}) => {
  const headers: any = { 'Content-Type': 'application/json' };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  return window.fetch(`${apiEndpoint}/${endpoint}`, config).then(async (response) => {
    if (response.ok) {
      return response.json();
    }

    const errorMessage = await response.text();
    return Promise.reject(new Error(errorMessage));
  });
}; */

export const filterFuzzyMatch = ({
  collection,
  propertyToFilter,
  filterValue,
}: {
  collection: FilterCollectionProps['collection'];
  propertyToFilter: FilterInOrder['propertyToFilter'];
  filterValue: FilterInOrder['filterValue'];
}) => {
  if (!filterValue) {
    return collection;
  }

  const options = {
    keys: [propertyToFilter],
  };

  const fuse = new Fuse(collection, options);

  return fuse.search(filterValue).map((item) => item.item);
};

export const filterCollection = ({ filtersInOrder, collection }: FilterCollectionProps) => {
  if (collection.length > 0) {
    const filter = ({
      collection,
      filterInOrder: { type, propertyToFilter, filterValue },
    }: {
      collection: { [key: string]: any }[];
      filterInOrder: FilterInOrder;
    }) => {
      const filters = {
        FUZZY_MATCH: () => filterFuzzyMatch({ collection, propertyToFilter, filterValue }),
      };

      return filters[type]();
    };

    let filteredCollection = collection;

    filtersInOrder.forEach((filterInOrder) => {
      filteredCollection = filter({ collection: filteredCollection, filterInOrder });
    });

    return filteredCollection;
  }

  return collection;
};

export const getParametersFromUrl = (parametersInUrl: string) => {
  const doesUrlHaveParameters = parametersInUrl.includes('?');

  if (parametersInUrl && doesUrlHaveParameters) {
    const parameters: { [key: string]: any } = {};

    parametersInUrl
      .split('?')[1]
      .split('&')
      .forEach((parametersInUrl) => {
        const parameterKeyValuePairs = parametersInUrl.split('=');

        Object.assign(parameters, { [parameterKeyValuePairs[0]]: parameterKeyValuePairs[1] });
      });

    return parameters;
  }

  return {};
};

export const fromDateAddDays = (date: Date, days: number) => {
  const newDate = new Date(date);

  newDate.setDate(newDate.getDate() + days);

  return newDate;
};

export const delayed = (action: () => void, delay: number) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      try {
        action();
      } catch (err) {
        reject();
      } finally {
        resolve(null);
      }
    }, delay);
  });

export const exportTextAsFile = (text: string) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'wallet-secret-phrase.txt';
  link.href = url;
  link.click();
};
