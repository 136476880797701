import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import { Button, CustomPopover, Image, Typography } from '../../components';

import { fromDateAddDays, getWallet } from '../../imports/utils/utils';
import { getNft } from '../../utils/getNFT';

import { IconLoading, IconMore } from '../../assets/icons';
import { ImageShare } from '../../assets/images';

import type { NftTaker } from '../../imports/types';

const Nft = () => {
  const { t } = useTranslation();

  const { id: nftId } = useParams();
  const [searchParams] = useSearchParams();

  const contractId = searchParams.get('contract');

  const wallet = getWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [nft, setNft] = useState<NftTaker | undefined>();

  const [isMorePopoverOpen, setIsMorePopoverOpen] = useState(false);

  const navigate = useNavigate();

  const doesExpire = nft?.contractExpiration !== 0;
  const isTransferable = nft?.contractTransfer !== 'none';

  const fetchNft = async () => {
    if (wallet && nftId) {
      setIsLoading(true);

      const { address } = wallet;

      const nft = await getNft(address, nftId, contractId!);
      setNft(nft);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNft();
  }, []);

  return isLoading ? (
    <div className="flex h-full w-full items-center justify-center">
      <IconLoading className="h-12 w-12 animate-spin text-white" />
    </div>
  ) : (
    <>
      {nft ? (
        <>
          <Image className="absolute h-2/5 w-full" src={nft.image} alt={nft.name} />
          <div className="container-system absolute top-[40%] h-3/5 w-full px-2 ">
            <header className="flex h-24 items-start justify-between py-4">
              <button type="button" aria-label={t('share')} onClick={() => {}}>
                <Image src={ImageShare} alt={t('share')} className="h-5 w-5 fill-white" />
              </button>
              <div className="-translate-y-1/2">
                {/* <div className="mx-auto h-24 w-24 overflow-hidden rounded-full border border-white/50 bg-dark">
                  <Image
                    src={nft.companyImage}
                    alt={nft.name}
                    className="h-full w-full object-cover"
                  />
                </div> */}
                <Typography
                  as="h1"
                  color="white"
                  weight="semibold"
                  className="mt-4 text-center text-[1.37rem]"
                >
                  {nft.name}
                </Typography>
              </div>

              <button
                type="button"
                aria-label={t('more')}
                onClick={() => setIsMorePopoverOpen((prevValue) => !prevValue)}
              >
                <CustomPopover
                  isOpen={isMorePopoverOpen}
                  positions={['bottom', 'right', 'top', 'left']}
                  content={
                    <>
                      <Link to="/transfer" state={{ contractId: nft.contractId, nftId: nft.id }}>
                        {t('transfer_action')}
                      </Link>
                      <Link to="/certify" state={{ contractId: nft.contractId, nftId: nft.id }}>
                        {t('certify_property')}
                      </Link>
                    </>
                  }
                  className="-translate-x-2.5 translate-y-2.5"
                >
                  <IconMore className="h-5 w-5 rotate-90 fill-white" />
                </CustomPopover>
              </button>
            </header>
            <SimpleBar className="h-[calc(100%-6rem)] w-full">
              <div className="min-h-full px-8 py-4">
                <div className="flex items-center justify-between">
                  <Typography as="h3" color="white" weight="semibold" size="lg">
                    {t('collection_name')}
                  </Typography>
                  <Typography color="white" size="lg">
                    {nft.companyName}
                  </Typography>
                </div>
                <Typography as="h2" color="white" className="mt-10">
                  {nft.description}
                </Typography>
                <div className="mt-10 flex items-center justify-between">
                  <Typography as="h3" color="white" weight="semibold" size="lg">
                    {t('expiration')}
                  </Typography>
                  <Typography color="white">{`${t(`boolean.${doesExpire}`)} - ${
                    doesExpire
                      ? format(
                          fromDateAddDays(
                            fromUnixTime(nft.contractCreatedAt),
                            nft.contractExpiration
                          ),
                          'dd/MM/yyyy'
                        )
                      : t('no_expiration')
                  }`}</Typography>
                </div>
                <div className="mt-4 flex items-center justify-between">
                  <Typography as="h3" color="white" weight="semibold" size="lg">
                    {t('transfer')}
                  </Typography>
                  <Typography color="white">{`${t(`boolean.${isTransferable}`)} - ${t(
                    `transfer_options.${nft.contractTransfer}`
                  )}`}</Typography>
                </div>
                <Button action={() => navigate(`/company/${nft.companyId}`)} className="mt-12">
                  {t('go_to_company')}
                </Button>
              </div>
            </SimpleBar>
          </div>
        </>
      ) : (
        <div className="flex h-full w-full items-center justify-center px-5">
          <Typography color="white" className="text-center">
            {t('nft_not_found')}
          </Typography>
        </div>
      )}
    </>
  );
};

export default Nft;
