import { useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { Header, Sidebar, WalletDialog } from '../components';

import type { DefaultPageProps, Routes } from '../imports/types';

import 'react-toastify/dist/ReactToastify.css';

const Background = () => (
  <div className="fixed top-0 left-0 -z-10 h-screen w-screen bg-dark">
    <svg
      viewBox="0 0 428 348"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute right-0 top-0 h-[348px] w-full -translate-y-1/4"
    >
      <g filter="url(#filter0_f_1349_7066)">
        <ellipse cx="303.5" cy="-19.5" rx="196.5" ry="72.5" fill="#365089" />
      </g>
      <defs>
        <filter
          id="filter0_f_1349_7066"
          x="-188"
          y="-387"
          width="983"
          height="735"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="147.5" result="effect1_foregroundBlur_1349_7066" />
        </filter>
      </defs>
    </svg>
    <svg
      viewBox="0 0 428 439"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="absolute left-0 bottom-0 h-[439px] w-full translate-y-1/3"
    >
      <g filter="url(#filter0_f_1349_7065)">
        <ellipse cx="69.5" cy="471.5" rx="196.5" ry="176.5" fill="#365089" />
      </g>
      <defs>
        <filter
          id="filter0_f_1349_7065"
          x="-422"
          y="0"
          width="983"
          height="943"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="147.5" result="effect1_foregroundBlur_1349_7065" />
        </filter>
      </defs>
    </svg>
  </div>
);

type LayoutProps = {
  route: Routes;
};

const Layout = ({ route }: LayoutProps) => {
  const { component, restrictedTo, redirectByRole, redirect, hasHeader, actionsInOrder } = route;

  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchBar, setSearchBar] = useState({ isOpen: false, search: '' });
  const [isWalletDialogOpen, setIsWalletDialogOpen] = useState(false);

  const Page: ({ searchBar }: DefaultPageProps) => JSX.Element = component;
  const hasRouteSidebar = actionsInOrder?.includes('menu');

  const handleSidebarClose = () => {
    setIsSidebarOpen(false);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((isSidebarOpen) => !isSidebarOpen);
  };

  const toggleSearchBar = () => {
    setSearchBar(({ isOpen, search }) => ({ isOpen: !isOpen, search }));
  };

  const updateSearch = (searched: string) => {
    setSearchBar(({ isOpen, search }) => ({ isOpen, search: searched }));
  };

  const toggleWalletDialog = () => {
    setIsWalletDialogOpen((isWalletDialogOpen) => !isWalletDialogOpen);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      toggleSidebar();
    }
  }, [pathname]);

  return (
    <div id="app" className="container-system h-screen w-full font-sans ">
      <Background />

      {hasHeader && (
        <Header
          actionsInOrder={actionsInOrder}
          toggleSidebar={toggleSidebar}
          searchBar={searchBar}
          toggleSearchBar={toggleSearchBar}
          updateSearch={updateSearch}
        />
      )}
      {hasRouteSidebar && (
        <Sidebar
          isOpen={isSidebarOpen}
          handleClose={handleSidebarClose}
          toggleWalletDialog={toggleWalletDialog}
        />
      )}
      <WalletDialog isOpen={isWalletDialogOpen} handleClose={toggleWalletDialog} />

      <Page searchBar={searchBar} />

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Layout;
