import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import { CardCompany, Typography } from '../../components';

import { IconLoading } from '../../assets/icons';

import { filterCollection } from '../../imports/utils/utils';
import { getCompanies } from '../../utils/getCompanies';

import type { Company, DefaultPageProps } from '../../imports/types';

const Companies = ({ searchBar }: DefaultPageProps) => {
  const { search } = searchBar;
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);

  const filteredCompanies = useMemo(
    () =>
      filterCollection({
        filtersInOrder: [
          {
            type: 'FUZZY_MATCH',
            propertyToFilter: 'name',
            filterValue: search,
          },
        ],
        collection: companies,
      }),
    [companies, searchBar]
  );

  const fetchCompanies = async () => {
    setIsLoading(true);

    const companies = await getCompanies();
    setCompanies(companies);

    setIsLoading(false);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <SimpleBar className="container-system absolute top-20 h-[calc(100%-5rem)] w-full ">
      <div className="flex min-h-full flex-col py-4">
        <Typography as="h1" color="white" className="text-center text-[1.37rem]">
          {t('companies')}
        </Typography>
        {isLoading ? (
          <div className="flex grow items-center justify-center">
            <IconLoading className="h-12 w-12 animate-spin text-white" />
          </div>
        ) : (
          <div className="mt-12">
            {filteredCompanies.length > 0 ? (
              <ul className="flex flex-col items-center justify-center space-y-8">
                {filteredCompanies.map(({ id, name, image, nfts }) => (
                  <Link to={`/company/${id}`} key={id}>
                    <div className="h-[7.43rem] w-[21.37rem] bg-dark">
                      <CardCompany name={name} image={image} numberOfNfts={nfts.length} />
                    </div>
                  </Link>
                ))}
              </ul>
            ) : (
              <Typography color="white" className="text-center">
                {t(
                  companies.length === 0
                    ? 'companies_collection_empty'
                    : 'companies_collection_filters_empty_result'
                )}
              </Typography>
            )}
          </div>
        )}
      </div>
    </SimpleBar>
  );
};

export default Companies;
