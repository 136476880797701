import { AnimatePresence, motion } from 'framer-motion';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';
import { Portal } from 'react-portal';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import Image from '../image/Image';
import OutsideAlerter from '../outsideAlerter/OutsideAlerter';

import { LogoLetter } from '../../assets/images';

import { sidebarMenuOrderList } from '../../imports/constants';
import routes from '../../imports/routes';
import { delayed } from '../../imports/utils/utils';

type SidebarProps = {
  isOpen: boolean;
  handleClose: () => void;
  toggleWalletDialog: () => void;
};
const Sidebar = ({ isOpen, handleClose, toggleWalletDialog }: SidebarProps) => {
  const { t } = useTranslation();

  return (
    <AnimatePresence>
      {isOpen && (
        <Portal>
          <div className="fixed top-0 left-0 z-20 h-screen w-screen bg-black/75">
            <OutsideAlerter onOutsideClick={handleClose}>
              <motion.aside
                initial={{ left: '-100%' }}
                animate={{ left: 0 }}
                exit={{ left: '-100%' }}
                transition={{ duration: 0.4 }}
                className="absolute top-0 left-0 h-full w-[13.37rem] bg-dark"
              >
                <SimpleBar className="h-full w-full p-5">
                  <header className="flex items-center justify-center">
                    <Image src={LogoLetter} alt={t('logo')} className="h-[4.37rem] w-[4.37rem]" />
                  </header>
                  <ul className="mt-20 flex flex-col">
                    <>
                      {sidebarMenuOrderList.map((item) => {
                        const menuItem = routes.find((route) => route.name === item);

                        return (
                          menuItem && (
                            <li key={item} className="mt-5">
                              <Link
                                to={!isArray(menuItem.path) ? menuItem.path : menuItem.path[0]}
                                className="text-lg text-white"
                              >
                                {t(`sidebar.menu_items.${menuItem.name}`)}
                              </Link>
                              <hr className="border-light" />
                            </li>
                          )
                        );
                      })}
                      <li className="mt-5">
                        <button
                          type="button"
                          className="text-lg text-white"
                          onClick={() => {
                            handleClose();
                            delayed(() => {
                              toggleWalletDialog();
                            }, 400);
                          }}
                        >
                          {t(`sidebar.menu_items.wallet`)}
                        </button>
                        <hr className="border-light" />
                      </li>
                    </>
                  </ul>
                </SimpleBar>
              </motion.aside>
            </OutsideAlerter>
          </div>
        </Portal>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
