import { ethers, utils } from 'ethers';
import { doc, DocumentData, getDoc } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { Location as ReactLocation } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SimpleBar from 'simplebar-react';
import * as yup from 'yup';

import { Button, Field, Input, Typography } from '../../components';

import { firebaseEndpoint } from '../../imports/constants';
import { db } from '../../imports/firebase';
import sdk from '../../utils/pablockSdk';

import transferAbi from '../../abi/transfer';

import { IconCircularClose } from '../../assets/icons';

import { getWallet } from '../../imports/utils/utils';
import { capitalizeFirstLetter } from '../../utils/common';

interface Location<State> extends Omit<ReactLocation, 'state'> {
  state: State;
}

type CustomState = {
  contractId?: string;
  nftId?: string;
};

const Transfer = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { state } = useLocation() as Location<CustomState>;

  const contractId = state?.contractId;
  const nftId = state?.nftId;

  const wallet = getWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [recipientAddress, setRecipientAddress] = useState('');
  const [error, setError] = useState('');

  const validateRecipientAddress = async (value: string) => {
    const isValid = await yup
      .string()
      .test('is-address-valid', t('form_validation.wallet_address_not_valid'), () =>
        utils.isAddress(value)
      )
      .isValid(value);
    return isValid;
  };

  const handleInputChange = async (event: React.ChangeEvent<any>) => {
    if (await validateRecipientAddress(event.target.value)) {
      setError('');
      setRecipientAddress(event.target.value);
      return;
    }

    setError(t('form_validation.wallet_address_not_valid'));
  };

  useEffect(() => {
    if (!contractId || !nftId) {
      navigate('/nfts');
    }
  }, []);

  const transferNft = async () => {
    const isRecipientAddressValid = await validateRecipientAddress(recipientAddress);

    if (isRecipientAddressValid && wallet) {
      setIsLoading(true);

      const { address, privateKey } = wallet;

      if (!sdk.isInitialized()) {
        await sdk.init();
      }
      sdk.setPrivateKey(privateKey);

      try {
        const docRef = doc(db, 'contracts', contractId!);
        const docSnapshot = await getDoc(docRef);
        let contract: DocumentData | null = null;

        if (docSnapshot.exists()) {
          contract = docSnapshot.data();
        } else {
          return null;
        }

        const metaTx = await sdk.prepareTransaction(
          {
            address: contract.address,
            abi: transferAbi,
            name: capitalizeFirstLetter(contract.name).replace(/\s/g, ''),
            version: '1.0',
          },
          'safeTransferFrom',
          [address, recipientAddress, nftId, 1, ethers.utils.formatBytes32String('')]
        );

        const requestId = await sdk.executeAsyncTransaction(metaTx, {
          webhookUrl: `${firebaseEndpoint}/saveReceipt`,
          verbose: true,
        });

        toast.success(t('nft_transfer_success'));
        navigate('/nfts');

        setIsLoading(false);
        return requestId;
      } catch (error) {
        toast.error(t('nft_transfer_error'));
        setIsLoading(false);

        return error;
      }
    }

    setError(t('form_validation.wallet_address_not_valid'));
    return null;
  };

  return (
    <SimpleBar className="container-system absolute top-20 h-[calc(100%-5rem)] w-full ">
      <div className="flex min-h-full flex-col items-center">
        <Typography as="h2" color="white" size="2xl" weight="semibold" className="text-center">
          {t('transfer_page_title')}
        </Typography>
        <div className="mx-auto mt-14 w-[17rem]">
          <Field error={error}>
            <Input
              type="text"
              placeholder={t('transfer_page_input_label')}
              onChange={handleInputChange}
              error={error}
              elementRight={
                <Button
                  type="ghost"
                  icon={IconCircularClose}
                  action={() => setRecipientAddress('')}
                />
              }
            />
          </Field>
        </div>
        <div className="grow" />
        <Button disabled={isLoading} action={transferNft} className="mx-auto mb-14 w-[17rem]">
          {t('transfer_page_action')}
        </Button>
      </div>
    </SimpleBar>
  );
};

export default Transfer;
