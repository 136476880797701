import axios from 'axios';
import { utils } from 'ethers';
import { documentId, where } from 'firebase/firestore';
import { rpcProvider, ipfsGateway } from '../imports/constants';
import { ECollections } from '../imports/enums';
import { Contract, Nft, NftTaker } from '../imports/types';
import { findDocument, getDocuments } from '../imports/utils/firestore.utils';

const retrieveNFT = async (address: string, tokenId: string) => {
  const query = !address.startsWith('0x')
    ? where(documentId(), '==', address)
    : where('address', '==', utils.getAddress(address));
  const contractData = await findDocument(ECollections.Contracts, query);

  //If the contract is found
  if (contractData) {
    const contract = contractData.data() as Contract;
    const dbNftData = await findDocument(
      `contracts/${contractData.id}/nfts`,
      where('id', '==', parseInt(tokenId, 16))
    );
    const dbNft = dbNftData?.data() as Nft;
    const companyData = await findDocument(
      ECollections.Users,
      where('address', '==', contract.owner)
    );
    const company = companyData?.data();

    return {
      ...dbNft,
      image: dbNft?.image.replace('ipfs://', ipfsGateway || 'https://ipfs.io/ipfs/'),
      companyName: contract.name,
      companyId: company?.uid,
      companyImage: company?.profilePhoto,
      contractId: contract.id,
      contractAddress: contract.address,
      contractExpiration: contract.expiration,
      contractCreatedAt: contract.createdAt,
      contractTransfer: contract.transfer,
    };
  }

  return undefined;
};

const getOwnedNFTs = async (address: string) => {
  const baseURL = `${rpcProvider}/getNFTs`;

  // Nfts from alchemy
  const ownedNftsFromChain = await (
    await axios({
      method: 'get',
      url: `${baseURL}?owner=${address}`,
    })
  ).data.ownedNfts;

  const ownedNfts = (
    await Promise.all(
      ownedNftsFromChain.map(async (nft: any) => {
        const retrievedNft = await retrieveNFT(nft.contract.address, nft.id.tokenId);
        return retrievedNft;
      })
    )
  ).filter((v) => !!v);

  return ownedNfts as NftTaker[];
};

const getNft = async (address: string, id: string, contractId: string) => {
  const nfts = await (await getOwnedNFTs(address)).filter((nft) => !!nft);

  return nfts.find((nft: any) => nft.id === Number(id) && nft.contractId === contractId);
};

export { getOwnedNFTs, getNft };
