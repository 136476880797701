import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';

import { CardNft, Typography } from '../../components';

import { filterCollection, getWallet } from '../../imports/utils/utils';
import { getOwnedNFTs } from '../../utils/getNFT';

import { IconLoading } from '../../assets/icons';

import type { DefaultPageProps, NftTaker } from '../../imports/types';

const Nfts = ({ searchBar }: DefaultPageProps) => {
  const { search } = searchBar;
  const { t } = useTranslation();

  const wallet = getWallet();

  const [isLoading, setIsLoading] = useState(false);
  const [nfts, setNfts] = useState<NftTaker[]>([]);

  const fetchOwnedNfts = async () => {
    if (wallet) {
      setIsLoading(true);

      const { address } = wallet;

      const nfts = await getOwnedNFTs(address);
      setNfts(nfts.filter((nft) => !!nft));

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOwnedNfts();
  }, []);

  const filteredNfts = useMemo(
    () =>
      filterCollection({
        filtersInOrder: [
          {
            type: 'FUZZY_MATCH',
            propertyToFilter: 'name',
            filterValue: search,
          },
        ],
        collection: nfts,
      }),
    [nfts, searchBar]
  );

  return (
    <SimpleBar className="container-system absolute top-20 h-[calc(100%-5rem)] w-full ">
      <div className="flex min-h-full flex-col py-4">
        <Typography as="h1" color="white" className="text-center text-[1.37rem]">
          {t('your_nft_collection')}
        </Typography>

        {isLoading ? (
          <div className="flex grow items-center justify-center">
            <IconLoading className="h-12 w-12 animate-spin text-white" />
          </div>
        ) : (
          <div className="mt-12">
            {filteredNfts.length > 0 ? (
              <ul className="flex flex-col items-center justify-center space-y-8">
                {filteredNfts.map(({ id, name, image, companyName, contractId }) => (
                  <Link to={`/nft/${id}?contract=${contractId}`} key={`${contractId}_${id}`}>
                    <div className="h-[20.12rem] w-[16.68rem]">
                      <CardNft name={name} image={image} companyName={companyName} />
                    </div>
                  </Link>
                ))}
              </ul>
            ) : (
              <Typography color="white" className="text-center">
                {t(
                  nfts.length === 0 ? 'nft_collection_empty' : 'nft_collection_filters_empty_result'
                )}
              </Typography>
            )}
          </div>
        )}
      </div>
    </SimpleBar>
  );
};

export default Nfts;
